import axios from 'axios';
import { BASE_URL } from 'constants/api';
import { Navigate } from 'react-router-dom';
import { setAuthState, setRoleState } from "redux/features/appStateSlice";
import { store } from 'redux/store';

//axios.defaults.baseURL = `${BASE_URL}/api`;
axios.defaults.baseURL = `${BASE_URL}`;

axios.interceptors.request.use(
  (successfulReq) => {
    console.log('successfulReq', successfulReq);
    const token = store.getState().appState?.authState;

    // If the user is logged in (i.e., token exists), add the token to headers
    if (token) {
      successfulReq.headers['Authorization'] = `Bearer ${token}`;
    }
    // const token = store.getState().appState?.authState;
    // if (token) {
    //   console.log('tokenC', token);
      // successfulReq.headers['x-auth-token'] = token;
    // }
    return successfulReq;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (successRes) => {
    return successRes;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // If the server returns a 401 Unauthorized, log the user out
      store.dispatch(setAuthState(null));
      store.dispatch(setRoleState([]))
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);
export default axios;
