import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthState, setRoleState } from "redux/features/appStateSlice";
import { authService } from "services/auth-services";
// import { createNotification } from 'components/react-notification';
import { useSelector } from "react-redux";
import { type appState } from "redux/features/appStateSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state: appState) => state.appState.roleState);
  const refreshToken = useSelector((state: appState) => state.appState.refreshState);
  const loggedInUser = useSelector((state: appState) => state.appState.loggedInUser);

  const onLogout = async () => {
    try {
      const result = await authService.logout({
        refresh: refreshToken
      });

      dispatch(setAuthState(null));
      dispatch(setRoleState([]));
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sizeConfigs.sidebar.width,

        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          'z-index': 0,
          justifyContent: "space-between",
        },
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "20px", display: "flex" }}>
          <Stack
            sx={{
              width: "100%",
              display: "flex",
            }}
            direction="row"
            justifyContent="center"
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                src={assets.images.logo}
                style={{ height: 110, width: 110, marginTop: 20 }}
              />
            </div>
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) =>
          route.roles?.includes(userRole) &&
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                loggedInUser.active ? (
                  route.state !== "setup" && (
                    <SidebarItem item={route} key={index} />
                  )
                ) : (
                  route.state === "setup" && <SidebarItem item={route} key={index} />
                )
                  // loggedInUser.active && route.state !== 'setup' && <SidebarItem item={route} key={index} /> ||
                  // !loggedInUser.active && route.state === 'setup' && <SidebarItem item={route} key={index} />
              )
            ) : null
        )}
      </List>
      <div>
        <div onClick={onLogout} className="flex justify-center mb-[3rem] w-full py-[12px] hover:text-white hover:bg-[#2F80ED] text-2xl hover:cursor-pointer">
          <span>Logout</span>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
