import ForgotPassword from "pages/auth/forgot-password";
import Login from "pages/auth/login";
import Signup from "pages/auth/signup";
import ResetPassword from "pages/auth/reset-password";
import SuccessScreen from "pages/auth/sucess-screen";

export const authRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/signup",
    component: <Signup />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/success",
    component: <SuccessScreen />,
  },
  {
    path: "/reset-password/:id",
    component: <ResetPassword />,
  },
];
