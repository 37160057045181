import React, { useEffect, useState } from 'react';
import Strategy from './strategy';
import Button from 'components/button';
import { getAllCoins, getAllUserStratiges } from 'services/strategies';
import { useSelector } from 'react-redux';
import { updateUser } from'services/users';
import Select from 'react-select'
import Loading from "components/loading";
import StrategyModal from './strategyModal';
import AutoEnabledModal from './autoEnabledModal';
import { useDispatch } from 'react-redux';
import { setLoggedInUser } from 'redux/features/appStateSlice';

const Strategies = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.appState.loggedInUser);
  const [strategies, setStrategies] = useState([]);
  const [coins, setCoins] = useState([]);
  const [coin, setCoin] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [strategyId, setStrategyId] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const EnableUserRecommendation = async (enabled) => {
    const response = await updateUser(loggedInUser.id, {
      full_name: loggedInUser.full_name,
      auto_recommended: enabled
    })
    setShowWarning(false)
    dispatch(setLoggedInUser(response.data))
  }

  const getAllStrategies = async (coin_id) => {
    setLoading(true);
    const result = await getAllUserStratiges(loggedInUser.id, coin_id);

    setLoading(false);
    setStrategies(result.data);
  }

  const getCoins = async () => {
    const result = await getAllCoins(true);

    const resultArray = result.data.map((elem) => ({
      label: elem.name.toUpperCase(),
      value: elem.id
    }));

    setCoins(resultArray);
  }

  const handleCoinSelect = async (element) => {
    setCoin(element)
    setStrategies([])

    if (element)
      getAllStrategies(element.value);
  }

  const handleStrategySelect = (strategy_id) => {
    setShowModal(true);
    setStrategyId(strategy_id)
  }

  useEffect(() => {
    getCoins();
  }, [])

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row w-full gap-4'>
        <div className='text-xl w-full'>
          Auto enable/disable strategies based on our recommendations.
        </div>
        <div className='w-full'>
          <Button
            text={ loggedInUser.auto_recommended ? 'Disable' : 'Enable' }
            btnClass={`${loggedInUser.auto_recommended ? "bg-red-700 hover:bg-red-800" : "bg-green-700 hover:bg-green-800"}`}
            handleClick={() => !loggedInUser.auto_recommended ? setShowWarning(!showWarning) : EnableUserRecommendation(false)}
          />
        </div>
      </div>

      {
        showWarning && <AutoEnabledModal showWarning={showWarning} EnableUserRecommendation={EnableUserRecommendation} setShowWarning={setShowWarning} />
      }

      {
        showModal && <StrategyModal showModal={showModal} setShowModal={setShowModal} strategies={strategies} strategy_id={strategyId} />
      }
      <div>
        <Select 
          options={coins}
          value={coin}
          isClearable={true}
          onChange={handleCoinSelect}
        />
      </div>
      {
        loading ? (<Loading loaderClass="w-[5rem] h-[5rem]" />) : (
          <div className={`grid ${strategies.length > 0 && 'md:grid-cols-3'} grid-wrap gap-9 md:ml-[30px]`}>
            {
              strategies.length > 0 ? strategies.map((user_strategy, index) => (
                <Strategy
                  strategies={strategies}
                  setStrategies={setStrategies}
                  user_strategy={user_strategy}
                  key={index}
                  coin={coin}
                  getStrategies={() => getAllStrategies(coin.value)}
                  onClickHandle={() => handleStrategySelect(user_strategy.id)}/>
              )) : (
                <div className='text-red-700 text-xl w-full'>
                  Please select Coin to see the strategies
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}

export default Strategies;
