import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getAllUsers } from'services/users';
import SelectBox from 'components/select-box';
import Select from 'react-select'
import { getAllCoins, getAllUserStratiges } from 'services/strategies';
import { getAllTrades, getTradesCount } from 'services/trades';
import TradeTable from 'pages/trades/tradeTable';

const AdminTrades = () => {
  const { control } = useForm();
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [strategies, setStrategies] = useState([])
  const [strategy, setStrategy] = useState(null)
  const [tradesCount, setTradesCount] = useState()
  // const [coins, setCoins] = useState([])
  const [coin, setCoin] = useState(null)
  const [trades, setTrades] = useState([])

  const fetchUsers = async () => {
    const result = await getAllUsers(true)
    const options = result.data.map((user) => ({
      label: user.email,
      value: user.id,
    }))

    setUsers(options)
  }

  const getCoins = async () => {
    const result = await getAllCoins(true);

    const resultArray = result.data.map((elem) => ({
      label: elem.name.toUpperCase(),
      value: elem.id
    }));

    // setCoins(resultArray);
    setCoin(resultArray[0]);
  }

  const getTradeCount = async (user_id, strategy_id) => {
    const result = await getTradesCount(user_id, strategy_id)

    setTradesCount(result.data.total.total_profit_or_loss)
  }

  const getAllStrategies = async (user_id) => {
    const result = await getAllUserStratiges(user_id, coin.value)

    const resultArray = result.data.map((elem) => ({
      label: elem.strategy_name,
      value: elem.id
    }))

    setStrategies(resultArray);
  }

  // const handleCoinSelect = async (element) => {
  //   setCoin(element)

  //   getAllStrategies(element.value);
  // }

  const getAllTradesFromAPI = async (user_id, strategy_id=null) => {
    const result = await getAllTrades(user_id, strategy_id)

    setTrades(result.data)
  }

  const handleStrategySelect = async (element) => {
    setStrategy(element)

    getAllTradesFromAPI(user.value, element.value)
    getTradeCount(user.value, element.value)
  }

  const handleUserSelect = (e) => {
    setUser(e)
  }

  useEffect(() => {
    fetchUsers();
    getCoins();
  }, [])

  useEffect(() => {
    if (user) {
      setStrategy(null)
      setStrategies([])

      getAllStrategies(user.value);
      getTradeCount(user.value, strategy?.value)
      getAllTradesFromAPI(user.value)
    }
  }, [user]);

  return (
    <div>
      <div className='flex flex-wrap flex-row justify-between'>
        <SelectBox
          placeholder={'Select User'}
          options={users}
          value={user}
          isMulti={false}
          control={control}
          name={'users'}
          customClass={"w-[100%] md:w-[40%]"}
          onChange={handleUserSelect}
        />

        {
          user && <Select 
            options={strategies}
            value={strategy}
            className='relative z-50 w-full md:w-[40%] mt-2 md:mt-0'
            onChange={handleStrategySelect}
            placeholder="Select Strategies"
          />
        }
      </div>

      {
        <TradeTable trades={trades} tradesCount={tradesCount} />
      }
    </div>
  )
}

export default AdminTrades;
