import React, { useEffect, useState } from "react";
import { getAllTrades } from 'services/trades';
import TradeTable from "pages/trades/tradeTable";
import { useSelector } from "react-redux";
import { getTradesCount } from 'services/trades';

const StrategyModal = (params) => {
  const { strategy_id, setShowModal, showModal, strategies } = params;
  const loggedInUser = useSelector((state) => state.appState.loggedInUser);
  const [tradesCount, setTradesCount] = useState()
  const strategy = strategies.filter(tr => tr.id === strategy_id)[0]
  const [trades, setTrades] = useState([])
  const strategy_info = `${strategy.rsi_time} ${strategy.rsi_type}-${strategy.buy_at}`

  const getTrades = async (user_id) => {
    const result = await getAllTrades(user_id, strategy_id)
    setTrades(result.data)
  }

  const getTradeCount = async (strategy_id) => {
    const result = await getTradesCount(loggedInUser.id, strategy_id)

    setTradesCount(result.data.total.total_profit_or_loss)
  }

  useEffect(() => {
    getTrades(process.env.REACT_APP_USER_ID, strategy_id);
    getTradeCount(strategy_id)
  }, [strategy_id])

  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center w-[100%] items-center flex overflow-none fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-[80%] overflow-n m-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {strategy.strategy_name} ({strategy_info})
                  </h3>
                  <button
                    className="p-1 ml-auto float-right font-semibold items-center"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-4xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="overflow-y-auto mx-[2rem]">
                  <TradeTable trades={trades} tradesCount={tradesCount} openModal={true}/>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default StrategyModal;
