import React, {useEffect, useState} from 'react';
import TradeTable from './tradeTable';
import { useSelector } from "react-redux";
import { getAllTrades } from 'services/trades';
import Select from 'react-select'
import { getAllCoins, getStrategies } from 'services/strategies';
import { getTradesCount } from 'services/trades';

const Trades = ({ user_id=null }) => {
  const loggedInUser = useSelector((state) => state.appState.loggedInUser)
  const [trades, setTrades] = useState([])
  const [tradesCount, setTradesCount] = useState()
  const [userId, setUserId] = useState(user_id || loggedInUser.id)
  const [coins, setCoins] = useState([])
  const [coin, setCoin] = useState()
  const [strategies, setStrategies] = useState([])
  const [strategy, setStrategy] = useState()

  const getTrades = async () => {
    const result = await getAllTrades(userId, strategy?.value, coin?.value)
    setTrades(result.data)
  }

  const getTradeCount = async () => {
    const result = await getTradesCount(loggedInUser.id, strategy?.value, coin?.value)

    setTradesCount(result.data.total.total_profit_or_loss)
  }

  const getCoins = async () => {
    const result = await getAllCoins(true)

    const resultArray = result.data.map((elem) => ({
      label: elem.name.toUpperCase(),
      value: elem.id
    }));

    setCoins(resultArray)
  }

  const getCoinStrategies = async () => {
    const result = await getStrategies(coin.value)

    const resultArray = result.data.map((elem) => ({
      label: elem.name.toUpperCase(),
      value: elem.id
    }));

    setStrategies(resultArray)
  }

  const handleCoinSelect = (element) => {
    setCoin(element)
    setStrategies([])
    setStrategy(null)
  }

  useEffect(() => {
    const fetchData = async () => {
      await getTrades();
      await getTradeCount();
  
      if (coin) {
        await getCoinStrategies();
      }

      if (user_id) {
        setUserId(user_id);
      }
    };

    fetchData();
  }, [user_id, coin, strategy]);

  useEffect(() => {
    getCoins()
  }, [])
  
  return (
    <>
      <div className='flex flex-wrap flex-col gap-4 lg:flex-row justify-between'>
        <Select
          placeholder="Please select coin"
          options={coins}
          value={coin}
          isClearable={true}
          className='w-full lg:w-2/5 z-50 text-black-500'
          onChange={handleCoinSelect}
        />

        <Select
          placeholder="Please select strategy"
          options={strategies}
          value={strategy}
          isClearable={true}
          className='w-full lg:w-2/5 z-20'
          onChange={(elem) => setStrategy(elem)}
        />
      </div>
      <TradeTable trades={trades} tradesCount={tradesCount} />
    </>
  )
}

export default Trades;
