import React from "react";
import Button from 'components/button';

const AutoEnabledModal = ({ setShowWarning, showWarning, EnableUserRecommendation }) => {
  if (!showWarning) return null;

  return (
    <div
      className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50"
      role="dialog"
      aria-labelledby="modal-heading"
      aria-modal="true"
    >
      <div className="relative w-[50%] w-content m-auto">
        {/* Modal Content */}
        <div className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-white">
          {/* Header */}
          <div className="flex items-center p-5 border-b border-solid border-blueGray-200 rounded-t gap-8">
            <h2 id="modal-heading" className="text-xl font-semibold">
              Auto Recommendations
            </h2>
            <button
              className="p-1 ml-auto float-right font-semibold items-center"
              onClick={() => setShowWarning(false)}
              aria-label="Close Modal"
            >
              <span className="text-4xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          {/* Body */}
          <div className="overflow-y-auto p-4">
            <article className="text-pretty text-lg">
              <p>We provide coin and strategy recommendations based on our expertise and market analysis. However, cryptocurrency markets are highly volatile and unpredictable, which may result in financial losses.</p>
              <p>Please note that USDTHEIST is not liable for any losses incurred while following these recommendations. We encourage you to conduct your own research and make informed investment decisions.</p>
            </article>
          </div>
          {/* Footer */}
          <div className='flex flex-row-reverse justify-between m-4'>
            <div>
              <Button
                text="Enable"
                btnClass={"w-full"}
                handleClick={() => EnableUserRecommendation(true)}
              />
            </div>
            <div>
              <Button
                text="Cancel"
                btnClass={"w-full bg-slate-400"}
                handleClick={() => setShowWarning(false)}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default AutoEnabledModal;
