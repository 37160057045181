import React, { useState } from 'react';
import Button from 'components/button';
import { updateUserStrategy, getStrategy, resetStrategy } from 'services/strategies';
import { buyTrade, sellTrade } from 'services/trades';
import { createNotification } from 'components/react-notification';

const Strategy = (params) => {
  const { coin, user_strategy, strategies, setStrategies, onClickHandle, getStrategies } = params
  const [strategyEnabled, setStrategyEnabled] = useState(user_strategy.enabled)
  const [amount, setAmount] = useState(user_strategy.amount)
  const [loading, setLoading] = useState(false)
  const [buyLoading, setBuyLoading] = useState(false)
  const [btnLoading, setBtnLoading]= useState(false)
  const strategy_info = `${user_strategy.buy_at}`

  const onClickHanlder = async () => {
    setLoading(true)
    try {
      const result = await updateUserStrategy(user_strategy.id, { ...user_strategy, amount: amount, enabled: !strategyEnabled });
      setStrategyEnabled(result.data.enabled)
      updateStrategies(result)
      createNotification(
        'success',
        'Trading ',
        `Strategy is successfully ${result.data.enabled ? 'started' : 'stopped'}`,
        10000
      )
    } catch(error) {
      createNotification(
        'error',
        'Trading Error ',
        error.response.data,
        10000
      )
    }

    setLoading(false)
  }

  const updateStrategies = (result) => {
    setStrategies((prevStrategies) => {
      const updatedStrategy = result.data;
      
      return prevStrategies.map(strategy =>
        strategy.id === updatedStrategy.id ? updatedStrategy : strategy
      );
    });
  }

  const fetchUserStrategy = async () => {
    const response = await getStrategy(user_strategy.id)
    updateStrategies(response)
  }

  const onClickSellHandler = async () => {
    setBuyLoading(true)
    try {
      await sellTrade(coin.value, user_strategy.id)
      fetchUserStrategy()
      createNotification(
        'success',
        'Sell ',
        'Trade is successfully completed',
        10000
      )
    } catch (error) {
      createNotification(
        'error',
        'Sell Error ',
        error.response.data,
        10000
      );
    }

    setBuyLoading(false)
  }

  const onClickBuyHanlder = async () => {
    setBuyLoading(true)
    try {
      await buyTrade(coin.value, user_strategy.id)
      fetchUserStrategy()
      createNotification(
        'success',
        'Buy ',
        'Trade is successfully completed',
        10000
      )
    } catch (error) {
      createNotification(
        'error',
        'Buy Trade',
        error.response.data,
        10000
      );
    }

    setBuyLoading(false)
  }

  const onUpdateHandler = async () => {
    setBtnLoading(true)
    try {
      await updateUserStrategy(user_strategy.id, { ...user_strategy, amount: amount });

      createNotification(
        'success',
        'Update ',
        'Strategy is successfully updated',
        10000
      )
    } catch(error) {
      createNotification(
        'error',
        'Update Error ',
        error.response.data,
        10000
      )
    }

    setBtnLoading(false)
  }

  const onResetHandler = async () => {
    try {
      const response = await resetStrategy(user_strategy.id)
      updateStrategies(response)

      createNotification(
        'success',
        'Reset ',
        'Strategy is successfully reset',
        10000
      )
    } catch(e) {
      createNotification(
        'error',
        'Reset Error ',
        e.response.data,
        10000
      )
    }
  }

  const amountHandler = (event) => {
    setAmount(event.target.value)
  }

  return (
    <div className={'flex flex-col bg-white max-w-sm p-3 rounded overflow-hidden shadow-lg relative overflow-hidden w-30 h-30 bg-white border'} id={user_strategy.id} key={`user_strategy_${user_strategy.d}`}>
      <div className='divide-y divide-black-300'>
        <div className='flex flex-row justify-between items-center'>
          <div className="text-3xl py-2">{user_strategy.strategy_name}<span className='text-lg'>({strategy_info})</span></div>
          <Button
            text="Refresh"
            btnClass="w-min"
            handleClick={onResetHandler}
          />
        </div>

        {
          user_strategy.recommended && (
            <div className="absolute right-0 top-0">
              <div
                className="absolute transform rotate-45 bg-yellow-500 text-center text-white text-sm right-[-50px] top-[25px] w-[170px]">
                Recommended
              </div>
            </div>
          )
        }

        <div className='flex p-2 flex-col'>
          <div className='flex p-2 justify-between'>
            <span>Amount:</span>
            <div>
              <input type="number" step=".01" value={amount} onChange={amountHandler} className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" placeholder="5.0" required/>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-between'>
        <Button
          isLoading={btnLoading}
          text="Update"
          btnClass={"w-[40%]"}
          handleClick={onUpdateHandler}
        />
        <Button
          text="View Trades"
          btnClass={"w-[40%]"}
          handleClick={onClickHandle}
        />
      </div>
      <div className='w-full mt-2'>
          <Button
            isLoading={loading}
            text={strategyEnabled ? 'Stop Trading' : 'Start Trading'}
            btnClass={`w-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none text-white font-medium rounded-lg ${strategyEnabled ? "bg-red-700 hover:bg-red-800" : "bg-green-700 hover:bg-green-800"}`}
            handleClick={onClickHanlder}
          />

          <Button
            isLoading={buyLoading}
            text={user_strategy.purchased ? 'Sell Now' : 'Buy Now'}
            btnClass={`w-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none text-white font-medium rounded-lg ${user_strategy.purchased ? "bg-red-700 hover:bg-red-800" : "bg-green-700 hover:bg-green-800"}`}
            handleClick={() => {user_strategy.purchased ? onClickSellHandler() : onClickBuyHanlder()}}
          />
        </div>
    </div>
  )
}

export default Strategy;
