import React, { useState } from "react";
import DataTable from 'components/table';

const TradeTable = ({ trades, tradesCount, openModal = false }) => {
  const [fromStrategy, setFromStrategy]  =  useState(openModal)

  const columns = [
    {
      title: "Coin",
      dataIndex: "coin",
      key: "coin",
      ellipsis: true,
      render: (text, index) => {
        return <div className="text-center">{text}</div>
      },
    },
    {
      title: "Amount",
      dataIndex: "buy_amount",
      key: "amount",
      ellipsis: true,
      render: (text, index) => {
        return <div className="text-center">{text}</div>
      },
    },
    {
      title: "Buy Price",
      dataIndex: "buy_price",
      key: "buy_price",
      ellipsis: true,
      render: (text, index) => {
        return <>{text}</>
      },
    },
    {
      title: "Buy Quantity",
      dataIndex: "buy_quantity",
      key: "buy_quantity",
      ellipsis: true,
      render: (text, index) => {
        return <>{text}</>
      },
    },
    {
      title: "Buy Commission",
      dataIndex: "buy_commission",
      key: "buy_commission",
      ellipsis: true,
      render: (text, index) => {
        return <>{text}</>
      },
    },
    {
      title: "Buy Date Time",
      dataIndex: "buy_date",
      key: "buy_date",
      ellipsis: true,
      width: 225,
      render: (text, index) => {
        const dateTime = new Date(text).toLocaleTimeString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })

        return <>{dateTime}</>
      },
    },
    {
      title: "Sell Price",
      dataIndex: "sell_price",
      key: "sell_price",
      ellipsis: true,
      render: (text, index) => {
        return <>{text}</>
      },
    },
    {
      title: "Sell Quantity",
      dataIndex: "sell_quantity",
      key: "sell_quantity",
      ellipsis: true,
      render: (text, index) => {
        return <>{text}</>
      },
    },
    {
      title: "Sell Commission",
      dataIndex: "sell_commission",
      key: "sell_commission",
      ellipsis: true,
      render: (text, index) => {
        return <>{text}</>
      },
    },
    {
      title: "Sell Date Time",
      dataIndex: "sell_date",
      key: "sell_date",
      ellipsis: true,
      width: 225,
      render: (text, index) => {
        const dateTime = new Date(text).toLocaleTimeString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })

        return <>{text !== null ? dateTime : ''}</>
      },
    },
    {
      title: "Profit/Loss",
      dataIndex: "profit_or_loss",
      key: "profit_or_loss",
      ellipsis: true,
      render: (text, index) => {
        return (
          text > 0? (
            <span className="text-green-500">{text}</span>
          ) : (
              <span className="text-red-500">{text}</span>
          )
        )
      },
    },
    {
      title: "PnL %",
      dataIndex: "profit_or_loss",
      key: "profit_or_loss",
      ellipsis: true,
      render: (text, strategy) => {
        const percentage = ((text / (strategy.buy_amount)) * 100).toFixed(4)
        return (
          text > 0? (
            <span className="text-green-500">{percentage} %</span>
          ) : (
              <span className="text-red-500">{percentage} %</span>
          )
        )
      },
    }
  ]

  const extendedColumns = () => {
    return fromStrategy ? columns : [{
      title: "Strategy Name",
      dataIndex: "strategy_name",
      key: "strategy_name",
      ellipsis: true,
      render: (text, index) => {
        return <div className="text-center">{text}</div>
      },
    }, ...columns ]
  }

  return (
    <>
      <div className="text-3xl my-[1rem] float-right font-semibold">
        Total Profit/Loss: {
          tradesCount > 0 ? (
            <span className="text-green-500">{tradesCount?.toFixed(4)}$</span>
          ) : (
              <span className="text-red-500">{tradesCount?.toFixed(4)}$</span>
          )
        }
      </div>
      <DataTable columns={extendedColumns()} data={trades} />
    </>
  );
}

export default TradeTable;
