import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import { routes } from './routes';
import { authRoutes } from 'routes/helper';
import './App.test.scss';
import { useSelector } from 'react-redux';

function App() {
  const access = useSelector((state) => state.appState.authState)
  const loggedInUser = useSelector((state) => state.appState.loggedInUser);

  return (
    <BrowserRouter>
      {access === null ? (
        <Routes>
          {authRoutes?.map(({ path, component }, index) => (
            <Route path={path} element={component} key={index} />
          ))}
          <Route path="*" element={<Navigate to={'/login'} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<MainLayout />}>
            {routes}
          </Route>
          <Route path="*" element={<Navigate to={loggedInUser.active ? '/strategies' : '/setup'} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
