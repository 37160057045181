import React, { useState } from "react";
import AWS from "aws-sdk";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { updateUser } from "services/users";
import { setLoggedInUser } from 'redux/features/appStateSlice';

const ApprovalForm = ({handle}) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.appState.loggedInUser)
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState(loggedInUser.payment_receipt_url);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: "ap-south-1",
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setUploadedUrl("")
  };

  const uploadFile = (file) => {
    const params = {
      Bucket: 'usdtheist',
      Key: `payments/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, function (err, data) {
      if (err) {
        console.error("Error uploading file:", err);
        return;
      }
      setUploadedUrl(data.Location);
      console.log("Successfully uploaded file:", data.Location);
    });
  };

  const submitHandler = async () => {
    const formData = {
      email: loggedInUser.email,
      full_name: loggedInUser.full_name,
      payment_receipt_url: uploadedUrl
    }

    const result = await updateUser(loggedInUser.id, formData)
    handle()
    dispatch(setLoggedInUser(result.data))
  }

  return (
    <div className="flex justify-around h-full">
      <div>
        <div className="text-2xl">Total Monthly subscription is 100$.</div>
        <div className="text-2xl">70$ will be charged after 1 month. Please send 30$ to this proceed and send the screenshot to us.</div>
        <div className="text-2xl">We will review it and will verify your account.</div>
        <img src={"https://usdtheist.s3.ap-south-1.amazonaws.com/binance-payment.jpeg"} alt="payment link" width={"500px"}></img>
      </div>
      <div className="h-fit">
        <h3>Upload the screenshot of your payment receipt</h3>
        <input type="file" accept="image/*" onChange={handleFileInput} className="m-[1rem]"/>

        {
          uploadedUrl ? (
            <div>
              <h4>Uploaded Image:</h4>
              <img src={uploadedUrl} alt="Uploaded" style={{ width: "300px" }} />
              <button type="submit" onClick={submitHandler} className="mt-[2rem] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit Request</button>
            </div>
          ) : (
            <div><button type="submit" onClick={() => uploadFile(selectedFile)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Upload</button></div>
          )
        }
      </div>
    </div>
  );
}

export default ApprovalForm;
